import React from 'react';
import { Button as RButton } from 'reactstrap';
import Loader from './Loader';

function Button({ type = 'button', text, helperText, onClick = null, color, className, style, disabled, isLoading }) {
  return (
    <>
      <RButton
        type={type}
        color={color}
        disabled={disabled || isLoading}
        onClick={onClick}
        className={`d-flex align-items-center mb-0 ${color === 'link' && 'p-0'} ${className}`}
        style={style}
      >
        {isLoading && <Loader inButton />}
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </RButton>
      {helperText && <p className="small pt-0">{helperText}</p>}
    </>
  );
}

export default Button;
