import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useStore from '../store';

function ProtectedRoute({ adminRoute }) {
  const tokenData = useStore((state) => state.tokenData);
  const isAdmin = tokenData?.token.accessToken.abilities.includes('admin');
  const location = useLocation();

  if (!tokenData) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  if (adminRoute && !isAdmin) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
