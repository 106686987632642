import React, { useState } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import { Collapse, Navbar as RNavbar, NavbarToggler, Nav, NavbarText, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useStore from '../store';
import useGames from '../utils/hooks/useGames';
import logo from '../assets/img/logo-golfamore-white.png';
import { useLogout } from '../utils/hooks/useUser';

function Navbar() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tokenData = useStore((state) => state.tokenData);

  const { mutate: logout } = useLogout();
  const { data: games } = useGames();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const onLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Container>
      {/* <RNavbar dark fixed="top" position="sticky" className="py-4"> */}
      <RNavbar dark className="py-4">
        <div className="d-flex flex-1 justify-content-center">
          <Link to="/">
            <img src={logo} alt="Logo" id="logotype" />
          </Link>
        </div>

        <NavbarToggler onClick={toggle} />

        <Collapse isOpen={isOpen} navbar onClick={toggle}>
          <div className="navbar-golfamore">
            <Nav className="me-auto" navbar>
              <NavbarText>{t('navbar.latest-competitions')}</NavbarText>
              {!tokenData && (
                <NavLink to="login" className="small">
                  {/* Log in in to view */}
                </NavLink>
              )}
              {games?.map((g) => (
                <p key={g.id} className="small mb-1 ps-2">
                  <Link to={`tournaments/${g.tournament_id}/games/${g.id}`}>
                    {g.name} ({g.code})
                  </Link>
                </p>
              ))}
              <NavLink to="how-it-works">{t('navbar.how-it-works')}</NavLink>
              {/* <NavLink to="rules">Rules</NavLink> */}
              <NavLink to="terms">{t('navbar.terms')}</NavLink>
              <NavLink to="about">{t('navbar.about')}</NavLink>

              {tokenData ? (
                <NavLink to="#" onClick={onLogout}>
                  {t('navbar.logout')}
                </NavLink>
              ) : (
                <NavLink to="login">{t('login.login')}</NavLink>
              )}
            </Nav>
          </div>
        </Collapse>
      </RNavbar>
    </Container>
  );
}

export default Navbar;
