import React from 'react';
import { Alert, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidEmail } from '../utils/helpers/validation';
import { useRegister } from '../utils/hooks/useUser';

function AccountCreate({ onSuccess }) {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { mutateAsync: login, error } = useRegister();

  const onSubmit = async (data) => {
    const rr = await login(data);
    if (rr.token) {
      onSuccess();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3>{t('login.create-account')}</h3>
      <label>{t('login.name')}</label>
      <input
        type="text"
        name="name"
        {...register('name', { required: true, minLength: 2, maxLength: 100 })}
        className={errors.name && 'error'}
      />

      <label>{t('login.email')}</label>
      <input
        type="email"
        autoComplete="email"
        {...register('email', { required: true, validate: isValidEmail })}
        className={errors.email && 'error'}
      />
      <label>{t('login.password')}</label>
      <input
        type="password"
        autoComplete="new-password"
        {...register('password', { required: true, minLength: 6 })}
        className={errors.password && 'error'}
      />
      <span className="small d-block">{t('login.password-help')}</span>
      {error && <Alert color="danger">{error}</Alert>}
      <div className="mt-3 d-flex justify-content-end">
        <Button type="submit" color="success">
          {t('login.create-account')}
        </Button>
      </div>
    </form>
  );
}

export default AccountCreate;
