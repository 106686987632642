/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

export default function TournamentCard({ data, img, onClick }) {
  const { t } = useTranslation();
  return (
    <Card className="tournament-card">
      <CardHeader style={{ minHeight: '70px' }} className="d-flex align-items-center">
        {/* eslint-disable-next-line react/no-danger */}
        <h4 className="flex-1 bold my-1" dangerouslySetInnerHTML={{ __html: data.name }} />
      </CardHeader>
      <CardBody>
        <p className="">
          {data.course}
          <br />
          {data.date}
        </p>
      </CardBody>
      <img src={img} className="responsive" alt="augusta" style={{ maxHeight: '200px' }} />
      <CardFooter className="py-2 d-flex flex-column justify-content-center">
        {data.registration_open ? (
          <Button color="white" outline onClick={() => onClick('register', data.id)}>
            {t('default.register')}
          </Button>
        ) : data.registration_closed ? (
          <Link to={`/tournaments/${data.id}`}>{t('default.show-results')} &raquo;</Link>
        ) : (
          <>
            <p className="mb-0">{`Opens on ${data.registration_start}`}</p>
            <Button color="link" className="bold py-0" onClick={() => onClick('remind', data.id)}>
              {t('default.remind-me')} &raquo;
            </Button>
          </>
        )}
      </CardFooter>
    </Card>
  );
}
