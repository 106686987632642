import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AccountCreate from './AccountCreate';
import AccountLogin from './AccountLogin';
import AccountPasswordReset from './AccountPasswordReset';
import Modal from './Modal';
import useStore from '../store';

function Authenticate() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const from = location.state?.from || null;

  const updateAuthenticationStatus = useStore((state) => state.setAuthenticate);
  const tokenData = useStore((state) => state.tokenData);
  const token = tokenData?.token.plainTextToken;

  const [currentView, setCurrentView] = useState('login');

  useEffect(() => {
    if (token) {
      navigate(from || '/', { replace: true });
    }
  }, [token, from, navigate]);

  const handleSuccess = () => {
    updateAuthenticationStatus(false);
    navigate(from || -1, { replace: true });
  };

  const toggleView = (view) => {
    setCurrentView(view);
  };

  const toggleButtons = useMemo(() => {
    if (currentView === 'login') {
      return (
        <>
          <Button color="link" onClick={() => toggleView('create')} className="pe-0">
            {t('login.create-account')} &raquo;
          </Button>
          <Button color="link" onClick={() => toggleView('reset')} className="pe-0">
            {t('login.reset-password')} &raquo;
          </Button>
        </>
      );
    }
    return (
      <Button color="link" onClick={() => toggleView('login')} className="pe-0">
        {t('login.login')} &raquo; &raquo;
      </Button>
    );
  }, [currentView]);

  const viewComponents = {
    login: <AccountLogin onSuccess={handleSuccess} />,
    create: <AccountCreate onSuccess={handleSuccess} />,
    reset: <AccountPasswordReset onSuccess={handleSuccess} />
  };

  return (
    <Modal isOpen centered onClose={() => navigate('/')}>
      {viewComponents[currentView]}
      <div className="d-flex justify-content-end">{toggleButtons}</div>
    </Modal>
  );
}

export default Authenticate;
