import React from 'react';

function Loader({ text = '', inButton = false, className = '' }) {
  const loaderClassName = `loader ${className} ${!inButton ? 'green big' : ''}`;

  return inButton ? (
    <>
      <span className={`loader ${loaderClassName}`} /> {text}
    </>
  ) : (
    <p className="loader-container">
      <span className={`loader ${loaderClassName}`} /> {text}
    </p>
  );
}

export default Loader;
