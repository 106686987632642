import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import TournamentReminder from '../components/TournamentReminder';
import augusta from '../assets/img/augusta.jpg';
import TournamentCard from '../components/TournamentCard';
import useTournaments from '../utils/hooks/useTournaments';
import ResultExample from '../components/ResultExample';

function Index() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: tournaments } = useTournaments();
  const [showReminder, setShowReminder] = useState(null);
  const [showExample, setShowExample] = useState(false);

  const onClickTournament = (type, id) => {
    if (type === 'remind') {
      setShowReminder(id);
    } else {
      navigate(`/tournaments/${id}`);
    }
  };

  return (
    <>
      <Container>
        <p className="color-white">{t('index.welcome')}</p>
        <div className="text-center mt-3">
          <Button onClick={() => setShowExample(true)}>{t('index.show-example')}</Button>
        </div>
        <Row className="mt-5 tournaments">
          {tournaments?.map((tm) => (
            <Col xs="12" sm="6" md="3" key={t.id} className="mb-4 m-sm-0">
              <TournamentCard data={tm} img={augusta} onClick={onClickTournament} />
            </Col>
          ))}
        </Row>
      </Container>
      {showReminder && <TournamentReminder id={showReminder} onClose={() => setShowReminder(null)} />}
      {showExample && <ResultExample modal onClose={() => setShowExample(false)} />}
    </>
  );
}

export default Index;
