import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePasswordReset } from '../utils/hooks/useUser';
import Button from './Button';

function AccountPasswordReset() {
  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { mutateAsync: resetPassword, error, isLoading } = usePasswordReset();

  const onSubmit = async (data) => {
    await resetPassword(data);
    setSuccess(true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3>{t('login.reset-password')}</h3>
      <label>{t('login.email')}</label>
      <input
        type="email"
        autoComplete="email"
        {...register('email', { required: true })}
        className={errors.email && 'error'}
      />
      {success && <Alert color="success">{t('login.reset-password-success')}</Alert>}
      {error && <Alert color="danger">{error}</Alert>}
      <div className="mt-3 d-flex justify-content-end">
        <Button color="success" type="submit" text={t('login.reset-password')} isLoading={isLoading} />
      </div>
    </form>
  );
}

export default AccountPasswordReset;
