import React from 'react';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';

function ResultExample({ modal = false, onClose }) {
  return modal ? (
    <Modal isOpen centered onClose={onClose}>
      <Example />
    </Modal>
  ) : (
    <Example />
  );
}

export default ResultExample;

function Example() {
  const { t } = useTranslation();

  return (
    <div className="d-flex m-4">
      <Table bordered>
        <thead>
          <tr>
            <td>{t('game.round')} 1</td>
            <td>{t('leaderboard.hole')} 1</td>
            <td>{t('leaderboard.hole')} 2</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('player.player')} 1</td>
            <td>-1</td>
            <td>-2</td>
          </tr>
          <tr>
            <td>{t('player.player')} 2</td>
            <td>0</td>
            <td>+1</td>
          </tr>
          <tr>
            <td>{t('player.player')} 3</td>
            <td>-1</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{t('player.player')} 4</td>
            <td>+1</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{t('player.player')} 5</td>
            <td>+2</td>
            <td>-1</td>
          </tr>
          <tr>
            <td>{t('leaderboard.your-result')}</td>
            <td>+1</td>
            <td>-2</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
