import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_ENV === 'local',
    backend: {
      loadPath: '/locales/{{lng}}.json'
    },
    supportedLngs: ['en', 'de', 'sv', 'dk'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
