import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Container } from 'reactstrap';
import Navbar from './components/Navbar';
import Game from './pages/Game';
import Authenticate from './components/Authenticate';
import ProtectedRoute from './components/ProtectedRoute';
import Index from './pages/Index';

const Tournaments = lazy(() => import('./pages/Tournaments'));
const Tournament = lazy(() => import('./pages/Tournament'));
const AdminIndex = lazy(() => import('./pages/backend/Index'));
const GameLeaderBoard = lazy(() => import('./pages/GameLeaderBoard'));
const HowItWorks = lazy(() => import('./pages/HowItWorks'));
const Terms = lazy(() => import('./pages/Terms'));
const About = lazy(() => import('./pages/About'));

function App() {
  return (
    <div className="App">
      <Navbar />
      <Container style={{ paddingTop: '40px' }}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route element={<ProtectedRoute adminRoute />}>
              <Route path="backend" element={<AdminIndex />} />
            </Route>
            <Route path="tournaments" element={<Tournaments />} />
            <Route path="tournaments/:id" element={<Tournament />} />
            <Route element={<ProtectedRoute />}>
              <Route path="tournaments/:id/games/:gameId" element={<Game />} />
              <Route path="tournaments/:id/games/:gameId/leaderboard" element={<GameLeaderBoard />} />
            </Route>
            <Route path="how-it-works" element={<HowItWorks />} />
            <Route path="terms" element={<Terms />} />
            <Route path="about" element={<About />} />
            <Route path="login" element={<Authenticate />} />
            <Route path="*" element={<p>Not found</p>} />
          </Routes>
        </Suspense>
      </Container>
    </div>
  );
}

export default App;
