import { useQuery } from '@tanstack/react-query';
import ApiClient from '../ApiClient';

const client = new ApiClient();

const KEY = 'tournaments';

export default function useTournaments() {
  return useQuery([KEY, 'all'], () => client.tournaments.get());
}

export function useTournament(tournamentId) {
  return useQuery([KEY, tournamentId], () => client.tournaments.get(tournamentId), {
    enabled: !!tournamentId
  });
}

export function useExternalTournaments() {
  return useQuery([KEY, 'external'], () => client.tournaments.getExternal());
}
