import HttpClient from './MyHttpClient';

class ApiClient extends HttpClient {
  constructor(baseURL = process.env.REACT_APP_API_BASE_URL, langCode = 'sv') {
    // console.log('process.env.REACT_APP_API_BASE_URL', process.env.REACT_APP_API_BASE_URL);
    super({
      baseURL,
      headers: {
        lang: langCode,
        accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        credentials: 'include'
      }
    });
  }

  users = {
    get: () => this.get('/users'),
    getMe: () => this.get('/users/me'),
    delete: (id) => this.delete(`/users${`/${id}`}`),
    create: (user) => this.post('/users', user),
    update: (user) => this.put(`/users${`/${user.id}`}`, user),
    login: (data) => this.post('/users/token', data),
    logout: () => this.post('/users/logout'),
    passwordReset: (data) => this.post('/users/password-reset', data),
    games: (gameId = '') => this.get(`/users/games${`/${gameId}`}`)
  };

  tournaments = {
    get: (id = null) => this.get(`/tournaments${id ? `/${id}` : ''}`),
    delete: (id) => this.delete(`/tournaments/${id}`),
    // create: (user) => this.post('/tournaments', user),
    create: (tournament) => this.post('/tournaments', tournament),
    update: (user) => this.put(`/tournaments/${user.id}`, user),
    players: (id, gameId, round) => this.get(`/tournaments/${id}/players?game-id=${gameId}&round=${round}`),
    removePlayer: ({ id, playerId, reason }) => this.delete(`/tournaments/${id}/players/${playerId}?reason=${reason}`),
    scorecard: (id, playerId) => this.get(`/tournaments/${id}/players/${playerId}/scorecard`),
    scoreUpdate: (id) => this.post(`/tournaments/${id}/score-update`),
    remindMe: ({ id, data }) => this.post(`/tournaments/${id}/user-reminder`, data),

    getExternal: () => this.get('/tournaments/external'),
    updateExternal: (id) => this.put(`/tournaments/${id}/external`)
  };

  games = {
    get: (id = null, tournamentId = null) =>
      this.get(`/games${id ? `/${id}/` : ''}${tournamentId ? `?tournament-id=${tournamentId}` : ''}`),
    delete: (id) => this.delete(`/games/${id}`),
    create: (user) => this.post('/games', user),
    update: (user) => this.put(`/games/${user.id}`, user),
    users: (id) => this.get(`/games/${id}/users`),
    addUser: (code) => this.post(`/games/${code}/users`),
    players: (id, userId = '') => this.get(`/games/${id}/players?user-id=${userId}`),
    // rounds: (id, userId = '') => this.get(`/games/${id}/rounds?user-id=${userId}`),
    scorecard: (id, userId = '') => this.get(`/games/${id}/users/${userId}/scorecard`),
    scoreHole: (id, roundNr, holeNr, userId = '') =>
      this.get(`/games/${id}/users/${userId}/scorecard?round=${roundNr}&hole=${holeNr}`),
    addPlayer: ({ gameId, id: playerId, round, pick, reserv }) =>
      this.post(`/games/${gameId}/players/${playerId}`, { pick, reserv, round }),
    removePlayer: ({ gameId, id: playerId, round }) =>
      this.delete(`/games/${gameId}/players/${playerId}`, { round }, { parseResponse: true }),
    replacePlayer: ({ gameId, id: playerId, round, replaceId }) =>
      this.put(`/games/${gameId}/players/${playerId}`, { round, replaceId })
  };

  settings = {
    get: (key = '') => this.get(`/admin/settings/${key}`),
    update: (key, value) => this.post('/admin/settings', { key, value })
  };
}

export default ApiClient;
