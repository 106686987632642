import React from 'react';
import { Alert, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLogin } from '../utils/hooks/useUser';

function AccountLogin({ navigateTo, onSuccess }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { mutateAsync: login, error } = useLogin();

  const onSubmit = async (data) => {
    try {
      const loginData = await login(data);
      if (loginData.token) {
        // eslint-disable-next-line no-unused-expressions
        navigateTo ? navigate(navigateTo) : onSuccess();
      }
      // eslint-disable-next-line no-empty
    } catch {}
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3>{t('login.login')}</h3>
      <label>{t('login.email')}</label>
      <input
        type="email"
        autoComplete="email"
        {...register('email', { required: true })}
        className={errors.email && 'error'}
      />
      <label>{t('login.password')}</label>
      <input
        type="password"
        autoComplete="current-password"
        {...register('password', { required: true, minLength: 4 })}
        className={errors.password && 'error'}
      />
      {error && <Alert color="danger">{error}</Alert>}
      <div className="mt-3 d-flex justify-content-end">
        <Button color="success" type="submit">
          {t('login.login')}
        </Button>
      </div>
    </form>
  );
}

export default AccountLogin;
