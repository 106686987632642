import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useStore = create(
  persist(
    (set, get) => ({
      authenticate: false,
      tokenData: null,
      user: null,
      setAuthenticate: (auth) => set({ authenticate: auth }),
      setTokenData: (data) => set({ tokenData: data }),
      setUser: (user) => set({ user }),
      getUserId: () => get().tokenData?.accessToken.id,
      showLeaderBoardGuide: true,
      setShowLeaderBoardGuide: () => set({ showLeaderBoardGuide: false })
    }),
    {
      name: 'fm-store' // name of the item in the storage (must be unique)
      // storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useStore;
