import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useStore from '../../store';
import ApiClient from '../ApiClient';

const client = new ApiClient();

const KEY = 'user';

export default function useUser() {
  const { tokenData } = useStore.getState();
  return useQuery([KEY], () => client.users.getMe(), { enabled: !!tokenData });
}

export function useRegister() {
  const queryClient = useQueryClient();
  const { setTokenData } = useStore.getState();

  return useMutation({
    mutationFn: (data) => client.users.create(data),
    onSuccess: (data) => {
      setTokenData(data);
      queryClient.resetQueries();
    }
  });
}

export function useLogin() {
  const queryClient = useQueryClient();
  const { setTokenData } = useStore.getState();

  return useMutation({
    mutationFn: (data) => client.users.login(data),
    onSuccess: (data) => {
      setTokenData(data);
      queryClient.resetQueries();
    }
  });
}

export function useLogout() {
  const queryClient = useQueryClient();
  const { setTokenData } = useStore.getState();

  return useMutation({
    mutationFn: (data) => client.users.logout(data),
    onSettled: () => {
      setTokenData(null);
      queryClient.clear();
      // queryClient.resetQueries({ queryKey: [KEY] });
      // queryClient.resetQueries({ queryKey: ['games'] });
    }
  });
}

export function usePasswordReset() {
  const queryClient = useQueryClient();
  const { setTokenData } = useStore.getState();

  return useMutation({
    mutationFn: (data) => client.users.passwordReset(data),
    onSettled: () => {
      setTokenData(null);
      queryClient.clear();
      // queryClient.resetQueries({ queryKey: [KEY] });
      // queryClient.resetQueries({ queryKey: ['games'] });
    }
  });
}
