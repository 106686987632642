import React from 'react';
import { Link } from 'react-router-dom';
import { Modal as RModal, ModalBody, Spinner } from 'reactstrap';
import Button from './Button';

function Modal({ isOpen, title, children, wide, inProgress, onClose, btnOk, btnCancel }) {
  return (
    <RModal isOpen={isOpen} centered className={wide ? 'wide' : ''}>
      <ModalBody>
        <div className="text-end pt-3 pe-1 p-sm-3">
          <button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
        </div>
        <div className="m-4 m-sm-5 mt-1 mt-sm-3">
          {title && <h3>{title}</h3>}
          {children}
          {btnOk && (
            <div className="mt-2 d-flex justify-content-end">
              {btnCancel && (
                <Button text={btnCancel.title} type="button" color="warning" className="me-4" onClick={onClose} />
              )}

              {/* eslint-disable-next-line no-nested-ternary */}
              {btnOk.to ? (
                <Link to={btnOk.to}>
                  <Button
                    text={btnOk.title}
                    type="button"
                    color="success"
                    isLoading={inProgress}
                    disabled={btnOk.disabled}
                  />
                </Link>
              ) : btnOk.onClick ? (
                <Button
                  text={btnOk.title}
                  type="button"
                  onClick={btnOk.onClick}
                  color="success"
                  isLoading={inProgress}
                  disabled={btnOk.disabled}
                />
              ) : (
                <Button
                  text={btnOk.title}
                  type="submit"
                  color="success"
                  isLoading={inProgress}
                  disabled={btnOk.disabled}
                />
              )}
            </div>
          )}
        </div>
      </ModalBody>
    </RModal>
  );
}

export default Modal;
