import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Input, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useTournamentPlayers from '../utils/hooks/useTournamentPlayers';
import { useUserGames } from '../utils/hooks/useGames';
import PlayerCard from '../components/PlayerCard';
import Modal from '../components/Modal';
import { useTournament } from '../utils/hooks/useTournaments';
import { fillMissingObjects } from '../utils/helpers/helpers';
import Loader from '../components/Loader';

const playersId = [1, 2, 3, 4, 5];
const reservPlayersId = [6, 7, 8, 9];
let questionAsked = false;

function Game() {
  const params = useParams();
  const { id: tournamentId, gameId } = params;

  const navigate = useNavigate();
  const { t, language } = useTranslation();

  const [game, setGame] = useState(null);
  const [pickedPlayers, setPickedPlayers] = useState(null);
  const [notPickedPlayers, setNotPickedPlayers] = useState(null);
  const [teamValue, setTeamValue] = useState(null);
  const [remainingTeamValue, setRemainingTeamValue] = useState(null);
  const [round, setRound] = useState(1);
  const [showModal, setShowModal] = useState(null);

  const { data: tournament } = useTournament(tournamentId);
  const { data: tournamentPlayers, isLoading, refetch } = useTournamentPlayers(tournamentId, gameId, round);

  const { data: allGames, refetch: refetchGames } = useUserGames();
  const games = allGames?.[tournamentId];

  const updateTeamAndPlayers = useCallback((_pickedPlayers, _notPickedPlayers, team) => {
    // const _teamValue = _pickedPlayers?.reduce((acc, p) => acc + (p.player_value || 0), 0);
    // const _remainingTeamValue = tournament.max_team_value - _teamValue;

    const updatetNotPickedPlayers = _notPickedPlayers.map((p) => ({
      ...p,
      disabled: p.player_value > team.remaining_value
    }));

    setTeamValue(team.value);
    setRemainingTeamValue(team.remaining_value);
    setNotPickedPlayers(updatetNotPickedPlayers);
    setPickedPlayers(_pickedPlayers);
  }, []);

  useEffect(() => {
    if (tournament && tournamentPlayers && game) {
      const _pickedPlayers = tournamentPlayers.filter((p) => p.pick);
      const _notPickedPlayers = tournamentPlayers.filter((p) => !p.pick);

      updateTeamAndPlayers(fillMissingObjects(_pickedPlayers, {}, 1, 9), _notPickedPlayers, game.team);
    }
  }, [tournamentPlayers, tournament, game, updateTeamAndPlayers]);

  useEffect(() => {
    if (games) {
      const _gameId = parseInt(gameId, 10);
      const _game = games.find((g) => g.id === _gameId);
      setGame(_game);
    }
  }, [gameId, games]);

  useEffect(() => {
    const nrPickedPlayers = pickedPlayers?.filter((p) => p.id).length;
    if (!questionAsked && nrPickedPlayers === 9) {
      questionAsked = true;
      setShowModal('picked-players-ready');
    }
  }, [pickedPlayers]);

  useEffect(() => {
    if (pickedPlayers && round > 1) {
      setNotPickedPlayers(pickedPlayers.filter((p) => p.pick > 5));
    }
  }, [round, pickedPlayers]);

  const onPlayerUpdate = useCallback(() => {
    refetch();
    refetchGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickToLeaderboard = () => {
    const nrPickedPlayers = pickedPlayers?.filter((p) => p.id).length;
    if (nrPickedPlayers < 9) {
      setShowModal('picked-players-less-than-9');
    } else {
      navigate(`/tournaments/${tournamentId}/games/${gameId}/leaderboard`);
    }
  };

  return (
    <>
      <Container>
        {game && tournament && (
          <>
            <Row className="mb-3">
              <Col
                xs="12"
                md="8"
                className="p-5"
                style={{ backgroundColor: 'rgba(256, 256, 256, 0.8)', borderRadius: '5px' }}
              >
                <h1>
                  {game.name} <br />
                  <span className="smaller">{tournament.name}</span>
                </h1>
                <p>
                  {t('game.description')}
                  <br />
                  <br />{' '}
                  <span className="bold">
                    {t('game.used')}: {teamValue?.toLocaleString(language)}$. {t('game.remaining')}:{' '}
                    {remainingTeamValue?.toLocaleString(language)}$
                  </span>
                </p>
                {game.user_is_admin && <p className="augusta">You are admin for the game</p>}
              </Col>
              <Col xs="12" md="4" className="text-end">
                <p className="m-0 color-white">
                  {t('default.invite-code')}: <b>{game.code}</b>
                </p>

                <a href="#" color="linkink" onClick={onClickToLeaderboard} className="bold text-white">
                  {t('default.to-leaderboard')} &raquo;
                </a>
              </Col>
            </Row>

            {tournament && (
              <>
                {tournament.started && (
                  <Row className="mb-5">
                    <Col>
                      <Input
                        type="select"
                        name="select"
                        value={round}
                        disabled={isLoading}
                        onChange={({ target }) => {
                          setNotPickedPlayers(null);
                          setPickedPlayers(null);
                          setRound(target.value);
                        }}
                      >
                        <option value="1">{t('game.round')} 1</option>
                        <option value="2">{t('game.round')} 2</option>
                        <option value="3">{t('game.round')} 3</option>
                        <option value="4">{t('game.round')} 4</option>
                      </Input>
                    </Col>
                  </Row>
                )}
                {isLoading && <Loader />}
                {pickedPlayers && (
                  <>
                    <Row className="mb-5">
                      {playersId.map((a) => (
                        <Col xs="12" sm="6" className="width-auto" key={a}>
                          <PlayerCard
                            id={a}
                            round={round}
                            initialPlayer={pickedPlayers[a - 1]}
                            gameId={gameId}
                            notPickedPlayers={notPickedPlayers}
                            pickedPlayers={pickedPlayers}
                            onPlayerUpdate={onPlayerUpdate}
                            canChange={tournament.open_for_registration && !tournament.started}
                            canReplace={
                              tournament.started &&
                              pickedPlayers[a - 1].current_round &&
                              round > pickedPlayers[a - 1].current_round
                            }
                          />
                        </Col>
                      ))}
                    </Row>

                    <div className="p-3 pb-5">
                      <Row>
                        <Col
                          xs="12"
                          md="8"
                          className="p-5"
                          style={{ backgroundColor: 'rgba(256, 256, 256, 0.8)', borderRadius: '5px' }}
                        >
                          <h5 className="bold">{t('game.bench')}</h5>
                          <p>{t('game.bench-description')}</p>
                        </Col>
                      </Row>
                    </div>

                    <Row className="mb-5">
                      {reservPlayersId.map((a) => (
                        <Col xs="12" sm="6" className="width-auto" key={a}>
                          <PlayerCard
                            id={a}
                            initialPlayer={pickedPlayers[a - 1]}
                            gameId={gameId}
                            notPickedPlayers={notPickedPlayers}
                            pickedPlayers={pickedPlayers}
                            onPlayerUpdate={onPlayerUpdate}
                            canChange={tournament.open_for_registration && !tournament.started}
                            reserv
                          />
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Container>

      {!!showModal && (
        <Modal
          isOpen
          centered
          title={`${t('game.ready')}?`}
          btnOk={{ title: t('default.to-leaderboard'), to: `/tournaments/${tournamentId}/games/${gameId}/leaderboard` }}
          btnCancel={{ title: t('leaderboard.edit-players') }}
          onClose={() => setShowModal(false)}
        >
          <p>{showModal === 'picked-players-ready' ? t('game.players-selected') : t('game.players-missing')}</p>
        </Modal>
      )}
    </>
  );
}

export default Game;
