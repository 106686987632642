import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useStore from '../../store';
import ApiClient from '../ApiClient';

const client = new ApiClient();

const KEY = 'games';

export default function useGames(tournamentId = null) {
  const tokenData = useStore((state) => state.tokenData);
  return useQuery([KEY, tournamentId || 'all'], () => client.games.get(null, tournamentId), {
    enabled: !!tokenData // && !!tournamentId
  });
}

export function useGame(tournamentId, gameId) {
  const tokenData = useStore((state) => state.tokenData);
  return useQuery([KEY, tournamentId, gameId], () => client.games.get(gameId, tournamentId), {
    enabled: !!tokenData && !!tournamentId && !!gameId
  });
}

export function useUserGames() {
  const tokenData = useStore((state) => state.tokenData);
  return useQuery([KEY], () => fetchGames(), {
    enabled: !!tokenData
  });
}

export function useCreateGame() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => client.games.create(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY] });
    }
  });
}

export function useJoinGame() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => client.games.addUser(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY] });
    }
  });
}

async function fetchGames(gameId = null, tournamentId = null) {
  const data = await client.games.get(gameId, tournamentId);
  return {
    all: data,
    ...groupBy(data, (g) => g.tournament_id)
  };
}

// eslint-disable-next-line no-return-assign, no-sequences, no-param-reassign
const groupBy = (x, f, r = {}) => (x.forEach((v) => (r[f(v)] ??= []).push(v)), r);
