import { useQuery } from '@tanstack/react-query';
import ApiClient from '../ApiClient';

const client = new ApiClient();

const KEY = 'tournaments';

export default function useTournamentPlayers(tournamentId, gameId, round = 1) {
  return useQuery([KEY, { tournamentId, gameId, round }], () =>
    client.tournaments.players(tournamentId, gameId, round)
  );
}
