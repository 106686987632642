/* eslint-disable no-nested-ternary */
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Alert, Card, CardBody, CardHeader, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import ApiClient from '../utils/ApiClient';
import Button from './Button';
import Modal from './Modal';
import i18n from '../i18n';

const client = new ApiClient();

const formatResult = (item) => {
  const name = `${item.name} <span class="small"><br />${item.player_value?.toLocaleString(i18n.language)} $</span>`;
  // eslint-disable-next-line react/no-danger
  return <p className={`mb-0 ${item.disabled && 'disabled'}`} dangerouslySetInnerHTML={{ __html: name }} />;
};

function PlayerCard({
  id: cardPosition,
  gameId,
  round = null,
  initialPlayer,
  notPickedPlayers,
  onPlayerUpdate,
  canChange,
  canReplace,
  reserv
}) {
  const [pickedPlayer, setPickedPlayer] = useState(initialPlayer);
  const [replacePlayer, setReplacePlayer] = useState(null);
  const [replacePlayerWithId, setReplacePlayerWithId] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setPickedPlayer(initialPlayer);
  }, [initialPlayer]);

  const addMutation = useMutation({
    mutationFn: client.games.addPlayer,
    onSuccess: (player, variables) => {
      setPickedPlayer(player);
      onPlayerUpdate('add', player, variables.pick);
    }
  });

  const rmMutation = useMutation({
    mutationFn: client.games.removePlayer,
    onSuccess: (player, variables) => {
      setPickedPlayer(null);
      onPlayerUpdate('rm', player, variables);
    }
  });

  const replaceMutation = useMutation({
    mutationFn: client.games.replacePlayer,
    onSuccess: (data, variables) => {
      setReplacePlayer(null);
      onPlayerUpdate('rp', pickedPlayer, variables);
    }
  });

  const onSelect = async (item) => {
    if (item.disabled) {
      return;
    }
    addMutation.mutate({ gameId, id: item.id, round: 1, pick: cardPosition, reserv });
  };

  const onRemove = async () => rmMutation.mutate({ gameId, id: pickedPlayer.id, round });

  const onReplace = async () => {
    replaceMutation.mutate({ gameId, id: replacePlayer.id, round, replaceId: replacePlayerWithId });
  };

  const resetErrors = () => {
    addMutation.reset();
    rmMutation.reset();
    replaceMutation.reset();
  };

  const error = addMutation.error || rmMutation.error || replaceMutation.error;

  return (
    <>
      {!pickedPlayer?.id && canChange && (
        <ReactSearchAutocomplete
          items={notPickedPlayers}
          placeholder={t('default.search')}
          formatResult={formatResult}
          onSelect={onSelect}
          styling={{ zIndex: 10, borderRadius: '5px' }}
          fuseOptions={{ shouldSort: true, threshold: 0.3, findAllMatches: false }}
        />
      )}
      <Card className="mt-2 mb-5">
        <CardHeader>
          <h4 className="bold my-1">
            {t('player.player')} {cardPosition} {reserv && t('player.reserve')}
          </h4>
        </CardHeader>
        <CardBody className="p-2">
          <p
            className="small m-0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: pickedPlayer?.name
                ? `${pickedPlayer.name}<br />${pickedPlayer.player_value?.toLocaleString('sv')}$`
                : 'Name'
            }}
          />
        </CardBody>
        <div className="augusta-bg">
          {addMutation.isLoading ? (
            <Loader inButton />
          ) : (
            <div>
              <div className="py-2">
                <div className={`silhouette ${pickedPlayer && `silhouette-${cardPosition}`}`} />
              </div>
            </div>
          )}
          {pickedPlayer?.gp_status && <p className="small">{pickedPlayer.gp_status}</p>}
          {pickedPlayer?.id && !pickedPlayer.gp_status && canChange && (
            <div className="d-flex mt-0 mb-3 justify-content-center">
              <Button color="white" outline onClick={onRemove} isLoading={rmMutation.isLoading} text="Remove" />
            </div>
          )}
          {pickedPlayer?.id && !pickedPlayer.gp_status && canReplace && (
            <div className="d-flex mt-0 mb-3 justify-content-center">
              <Button color="white" outline onClick={() => setReplacePlayer(pickedPlayer)} text="Replace" />
            </div>
          )}
        </div>
      </Card>

      {error && (
        <Modal isOpen centered onClose={resetErrors}>
          <Alert color="danger" className="mb-0" style={{ borderRadius: 0 }}>
            {error}
          </Alert>
        </Modal>
      )}

      {replacePlayer && (
        <Modal
          isOpen
          centered
          inProgress={replaceMutation.isLoading}
          btnOk={{ title: 'Replace', onClick: onReplace, disabled: !replacePlayerWithId }}
          onClose={() => setReplacePlayer(null)}
        >
          {t('player.replace-with-question', { name: replacePlayer.name, round })}

          <Input
            type="select"
            className="mt-2"
            onChange={({ target }) => setReplacePlayerWithId(parseInt(target.value, 10))}
          >
            <option value="">{t('player.replace-with')}...</option>
            {notPickedPlayers?.map((player) => (
              <option key={player.id} value={player.id} disabled={!['active', 'complete'].includes(player.status)}>
                {player.name} ({player.status})
              </option>
            ))}
          </Input>
        </Modal>
      )}
    </>
  );
}

// export default PlayerCard;
export default React.memo(PlayerCard);
