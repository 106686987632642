import React from 'react';
import { Alert, Modal, ModalBody } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import Button from './Button';
import ApiClient from '../utils/ApiClient';
import { isValidEmail } from '../utils/helpers/validation';
import augusta from '../assets/img/augusta.jpg';

const client = new ApiClient();

function TournamentReminder({ id, onClose }) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const {
    mutate: remindMe,
    error,
    isLoading,
    isSuccess
  } = useMutation({
    mutationFn: client.tournaments.remindMe
  });

  const onSubmit = async (data) => {
    remindMe({ id, data });
  };

  return (
    <Modal isOpen centered className="wide">
      <ModalBody>
        <div className="d-flex">
          <img src={augusta} alt="augusta" />
          <div className="d-flex flex-1 flex-column">
            <div className="text-end pt-3 pe-3">
              <button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
            </div>

            <div className="m-5">
              <h3>Påminn mig</h3>
              <p className="mb-3">Ange din e-post adress nedan så påminner vi dig när tävlingen öppnar</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="email">E-post</label>
                <input
                  type="email"
                  {...register('email', { required: true, validate: isValidEmail })}
                  className={errors.email && 'error'}
                />
                <label htmlFor="emailRepeat" className="mt-1">
                  Upprepa e-post
                </label>
                <input
                  type="email"
                  {...register('emailRepeat', { required: true, validate: isValidEmail })}
                  className={errors.emailRepeat && 'error'}
                />
                {isSuccess && (
                  <Alert color="success">Din påminnelse är registrerad, vi återkommer när tävlingen startar</Alert>
                )}
                {error && <Alert color="danger">{error}</Alert>}
                <div className="mt-3 d-flex justify-content-end">
                  <Button text="Remind me" color="success" type="submit" disabled={isLoading} isLoading={isLoading} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default TournamentReminder;
